.hero .text {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  animation: slideUp 3s forwards;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 72px;
  font-weight: bolder;
  color: white;
  text-align: center;
  text-transform: uppercase;
}
@media screen and (max-width: 900px) {
  .hero .text {
    font-size: 48px;
  }
}
.zoom-pan-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-height: 500px;
  /* Adjust the maximum height as needed */
}
#fwkBoard {
  padding: 0 !important;
}
#zoom-pan-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  animation: zoomPan 20s forwards;
}
@keyframes zoomPan {
  0% {
    transform: scale(1) translate(0, 0);
  }
  50% {
    transform: scale(1.2) translate(-5%, -5%);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}
