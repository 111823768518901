@font-face {
  font-family: "Helvetica Neue Light";
  src: url("./fonts/Helvetica/HelveticaNeue-Light.otf");
}
@font-face {
  font-family: "Helvetica Neue Bold";
  src: url("./fonts/Helvetica/HelveticaNeue-Medium.otf");
}
* {
  box-sizing: border-box;
  outline: none;
}
html,
body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue Light", Helvetica, Arial, 'Roboto', sans-serif;
  font-size: 10pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
}
.relVersion {
  margin: 20px;
}
.relVersion h2 {
  text-decoration: underline;
}
