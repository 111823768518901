#privacy #herotext {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  animation: slideUp 3s forwards;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 72px;
  font-weight: bolder;
  color: white;
  text-align: center;
  text-transform: uppercase;
}
#privacy #band1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
#privacy #consultancy {
  color: white;
  background-color: #de421b;
}
#privacy #techservices {
  color: #ffffff;
  background-color: #64666d;
  min-height: 419px;
}
.hero {
  width: 100%;
}
