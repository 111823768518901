.hidden {
  display: none;
}
.fwkNavbar a {
  color: white;
  font-size: larger;
  font-weight: 700;
}
h1,
h2,
h3 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
h1 {
  font-size: 48pt;
}
h2 {
  font-size: 32pt;
}
h3 {
  font-size: 20pt;
}
a {
  color: black;
}
.fwkPageBand {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.infoBox {
  padding: 94px 80px;
  font-size: 15px;
}
@keyframes slideUp {
  0% {
    bottom: -50px;
    /* Adjust to fit your needs */
    opacity: 0;
  }
  100% {
    bottom: 50%;
    opacity: 1;
  }
}
