footer {
  display: flex;
  flex-direction: column;
  padding: 25px;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  line-height: 1.3;
}
footer .logo {
  display: flex;
  width: 250px;
  height: 100%;
  align-self: center;
}
footer .sections {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-bottom: 20px;
  flex-grow: 1;
}
footer > div {
  display: flex;
  width: 100%;
}
footer > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
footer h2 {
  padding-bottom: 17px;
  line-height: 23px;
  text-transform: uppercase;
}
footer p {
  margin: 0;
}
footer ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
footer ul li {
  display: flex;
  column-gap: 10px;
  align-items: start;
  margin: 0;
  padding: 0;
  padding-bottom: 9px;
  min-height: 28px;
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
}
footer ul li a {
  color: rgba(255, 255, 255, 0.8);
}
footer ul li a a:hover,
footer ul li a a:focus,
footer ul li a a:active,
footer ul li a a.active {
  text-decoration: none;
}
