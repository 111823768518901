#home #herotext {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  animation: slideUp 3s forwards;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 72px;
  font-weight: bolder;
  color: white;
  text-align: center;
  text-transform: uppercase;
}
#home #socialicons {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  column-gap: 15px;
  padding-right: 20px;
  padding-bottom: 30px;
}
#home #band1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
#home #techservices {
  color: #ffffff;
  background-color: #64666d;
  min-height: 419px;
}
#home #offices {
  color: black;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}
#home #offices .text {
  color: black;
}
#home #offices div {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  padding-right: 20px;
  padding-bottom: 30px;
}
.hero {
  width: 100%;
}
