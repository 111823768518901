#home #herotext {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  animation: slideUp 3s forwards;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 72px;
  font-weight: bolder;
  color: white;
  text-align: center;
  text-transform: uppercase;
}
#home #socialicons {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  column-gap: 15px;
  padding-right: 20px;
  padding-bottom: 30px;
}
#home #band1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
#home #consultancy {
  color: white;
  background-color: var(--accent-colour);
}
#home #instagram {
  font-size: 36px;
  text-align: center;
  padding: 30px;
  min-height: 100px;
}
#home #whatsapp {
  display: flex;
  align-self: flex-end;
  justify-content: end;
  column-gap: 20px;
  font-size: 36px;
  color: white;
  text-align: center;
  padding: 30px;
  min-height: 100px;
}
.hero {
  width: 100%;
}
